exports.components = {
  "component---src-page-types-app-overview-tsx": () => import("./../../../src/pageTypes/AppOverview.tsx" /* webpackChunkName: "component---src-page-types-app-overview-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-best-apps-of-the-month-tsx": () => import("./../../../src/pages/best-apps-of-the-month.tsx" /* webpackChunkName: "component---src-pages-best-apps-of-the-month-tsx" */),
  "component---src-pages-best-apps-of-the-week-tsx": () => import("./../../../src/pages/best-apps-of-the-week.tsx" /* webpackChunkName: "component---src-pages-best-apps-of-the-week-tsx" */),
  "component---src-pages-best-games-of-the-month-tsx": () => import("./../../../src/pages/best-games-of-the-month.tsx" /* webpackChunkName: "component---src-pages-best-games-of-the-month-tsx" */),
  "component---src-pages-best-games-of-the-week-tsx": () => import("./../../../src/pages/best-games-of-the-week.tsx" /* webpackChunkName: "component---src-pages-best-games-of-the-week-tsx" */),
  "component---src-pages-best-workout-apps-2022-tsx": () => import("./../../../src/pages/best-workout-apps-2022.tsx" /* webpackChunkName: "component---src-pages-best-workout-apps-2022-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

