import '@fontsource/montserrat'
import '@fontsource/lora'
import '@fontsource/hind-madurai'

import { extendTheme } from '@chakra-ui/react'

const theme = {
    fonts: {
        heading: `Montserrat`,
        // subHeading: `Lora`,
        subHeading: `Montserrat`,
        body: `Hind Madurai`,
    },
    colors: {
        primary: {
            50: '#e6f3ff',
            100: '#c7d7eb',
            200: '#a6bcd9',
            300: '#84a2c8',
            400: '#6287b7',
            500: '#486d9d',
            600: '#37557b',
            700: '#263d59',
            800: '#152438',
            900: '#020c19',
        },
        secondary: {
            50: '#ffe3e5',
            100: '#ffb5ba',
            200: '#fa868d',
            300: '#f65560',
            400: '#f22633',
            500: '#d90d19',
            600: '#aa0713',
            700: '#79030d',
            800: '#4b0005',
            900: '#1f0000',
        },
        highlight: {
            50: '#faf5ea',
            100: '#efe0c6',
            200: '#e5cb9f',
            300: '#dbb777',
            400: '#d2a250',
            500: '#b88937',
            600: '#906b2c',
            700: '#664c1f',
            800: '#3d2d13',
            900: '#150f05',
        },
    },
}

export default extendTheme(theme)
